<template>
    <div style="padding:20px 40px;background-color: white;height: 100%">
      <a-modal style="min-width: 800px;" v-model:visible="visible" title="模型上传" @ok="handleOk" ok-text="确认" cancel-text="取消">
  
        <a-layout>
          <a-layout-sider>
            <a-menu v-model:selectedKeys="selectedKeys" mode="inline" style="height: 100%">
              <a-menu-item key="1" @click="showUploadString">上传代码</a-menu-item>
              <a-menu-item key="2" @click="showUploadFile">上传文件</a-menu-item>
              <a-menu-item key="3" @click="showUploadFromGitHub">从github</a-menu-item>
            </a-menu>
          </a-layout-sider>
          <a-layout>
            <a-layout-content style="background-color: white;">
  
              <a-form v-if="uploadString" class="uploadForm" :model="formState" name="basic" :wrapper-col="{ span: 16 }"
                autocomplete="off" @finish="onFinishString" @finishFailed="onFinishFailed">
                <a-form-item style="border-bottom:1px solid rgb(179, 179, 179); padding-bottom: 20px;" name="modelTitle"
                  :wrapper-col="{ span: 24 }" :rules="[{ required: true, message: '请输入模型名称！' }]">
                  <a-input v-model:value="formState.modelTitle" placeholder="模型名称" />
                </a-form-item>
  
                <a-form-item :wrapper-col="{ span: 24 }" name="trainScript"
                  :rules="[{ required: true, message: '请输入训练使用的脚本!' }]">
                  <a-input v-model:value="formState.trainScript" placeholder="训练shell脚本" />
                </a-form-item>
  
                <a-form-item :wrapper-col="{ span: 24 }" name="predictScript"
                  :rules="[{ required: true, message: '请输入预测使用的脚本!' }]">
                  <a-input v-model:value="formState.predictScript" placeholder="预测shell脚本" />
                </a-form-item>
                <a-form-item :wrapper-col="{ span: 24 }" name="script">
                  <p>在下方粘贴模型代码</p>
                </a-form-item>
                <a-form-item :wrapper-col="{ span: 24 }" name="script">
                  <a-textarea v-model:value="formState.script" placeholder="单文件模型代码" :rows="4" />
  
                </a-form-item>
  
                <a-form-item :wrapper-col="{ span: 24 }">
                  <a-button type="primary" html-type="submit">上传</a-button>
                </a-form-item>
              </a-form>
              <a-form v-if="uploadFile" class="uploadForm" :model="formState" name="basic" :wrapper-col="{ span: 16 }"
                autocomplete="off" @finish="onFinish" @finishFailed="onFinishFailed">
                <a-form-item :wrapper-col="{ span: 24 }" name="modelTitle" style="border-bottom:1px solid rgb(179, 179, 179);
                  padding-bottom: 20px;" :rules="[{ required: true, message: '请输入模型名称!' }]">
                  <a-input v-model:value="formState.modelTitle" placeholder="请输入模型名称" />
                </a-form-item>
  
                <a-form-item :wrapper-col="{ span: 24 }" name="trainScript"
                  :rules="[{ required: true, message: '请输入训练使用的脚本!' }]">
                  <a-input v-model:value="formState.trainScript" placeholder="训练shell脚本" />
                </a-form-item>
  
                <a-form-item name="predictScript" :wrapper-col="{ span: 24 }"
                  :rules="[{ required: true, message: '请输入预测使用的脚本!' }]">
  
                  <a-input v-model:value="formState.predictScript" placeholder="预测shell脚本" />
                </a-form-item>
  
  
                <a-form-item :wrapper-col="{ span: 24 }">
                  <a-form-item :wrapper-col="{ span: 24 }" name="file" no-style>
                    <a-upload-dragger v-model:fileList="formState.file" name="files" action="/upload.do">
                      <p class="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p class="ant-upload-text">点击选择或拖拽压缩包文件</p>
                      <p class="ant-upload-hint">支持单个上传或多个上传</p>
                    </a-upload-dragger>
                  </a-form-item>
                </a-form-item>
  
                <a-form-item :wrapper-col="{ span: 24 }">
                  <a-button type="primary" html-type="submit">上传</a-button>
                </a-form-item>
              </a-form>
              <a-form v-if="uploadFromGitHub" class="uploadForm" :model="formState" name="basic" :wrapper-col="{ span: 16 }"
                autocomplete="off" @finish="onFinishGithub" @finishFailed="onFinishFailed">
                <a-form-item :wrapper-col="{ span: 24 }"
                  style="border-bottom:1px solid rgb(179, 179, 179);padding-bottom:  20px;" name="modelTitle"
                  :rules="[{ required: true, message: 'Please input your username!' }]">
                  <a-input v-model:value="formState.modelTitle" placeholder="请输入模型名称" />
                </a-form-item>
  
                <a-form-item :wrapper-col="{ span: 24 }" name="trainScript"
                  :rules="[{ required: true, message: '请输入训练使用的脚本!' }]">
                  <a-input v-model:value="formState.trainScript" placeholder="训练shell脚本" />
                </a-form-item>
  
                <a-form-item :wrapper-col="{ span: 24 }" name="predictScript"
                  :rules="[{ required: true, message: '请输入预测使用的脚本!' }]">
                  <a-input v-model:value="formState.predictScript" placeholder="预测shell脚本" />
                </a-form-item>
  
                <a-form-item :wrapper-col="{ span: 24 }">
                  <p>从GitHub仓库引入代码</p>
                </a-form-item>
  
                <a-form-item :wrapper-col="{ span: 24 }" name="githubHref"
                  :rules="[{ required: true, message: '请输入github仓库链接' }]">
                  <a-input style="height: 50px;" v-model:value="formState.githubHref" placeholder="github链接" />
                </a-form-item>
  
                <a-form-item :wrapper-col="{ span: 24 }">
                  <a-button type="primary" html-type="submit">上传</a-button>
                </a-form-item>
              </a-form>
            </a-layout-content>
          </a-layout>
        </a-layout>
  
  
      </a-modal>
  
      <a-row>
        <a-col :span="10">
          <span style="font-size: 40px;font-weight: bold;display: inline-block;margin-bottom: 30px">模型池</span>
          <br>
          各种已经训练好的模型
          <br>
          <br>
          您可以在这里查看各个模型的版本信息，并为点云数据分给合适的模型
          <br>
          <div style="margin-top:40px">
            <a-space>
              <a-button @click="showModal" style="font-size:20px;font-weight:400;
              height: 40px;width: 200px;border-radius: 50px;">
                <a-icon type="plugs"></a-icon>上传新模型
              </a-button>
              <a-button
                style="font-size:20px;font-weight: 400;;height: 40px;width:150px;border-radius: 50px;">我的模型</a-button>
            </a-space>
          </div>
        </a-col>
        <a-col :span="1"></a-col>
        <a-col :span="10">
          <img style="float: right" height="300" width="300"
            src="https://www.kaggle.com/static/images/datasets/Datasets_landing_illo@2x.png" alt="">
        </a-col>
      </a-row>
  
  
      <a-input-search size="large" v-model:value="value" placeholder="搜索模型" enter-button @search="onSearch" />
  
      <a-row>
        <a-col :span="6">
          <a-card hoverable style="height: 200px;width:300px">
            <template #cover>
              <img width="300" height="130" alt="example" :src="require('../../assets/images/model1.png')" />
            </template>
            <span style="letter-spacing: 0.3em;font-size: 20px;font-weight: bold">单木实例分割模型</span>
          </a-card>
        </a-col>
        <a-col style="overflow-x: scroll;overflow-y: hidden;" :span="18">
          <a-space>
            <a-card v-for="index in 1" :key="index" hoverable style="height: 200px;width:300px">
              <template #cover>
                <div style="padding: 20px">
                  <div style="font-weight: bold;font-size:20px;margin-bottom:10px ">3D-Unet v2.0</div>
                  语言 · PyTorch <br>
                  3D u-net点云分割 <br>
                  将不同点云不同的物体分割
                </div>
                <div style="border-top: 1px solid #dadce0;height: 50px;line-height: 50px;padding-left: 20px">
                  <a-rate :value="4" />
                </div>
              </template>
            </a-card>
            
          </a-space>
        </a-col>

      </a-row>
  
  
      <a-row>
        <a-col :span="6">
          <a-space>
            <a-card hoverable style="height: 200px;width:300px">
              <template #cover>
                <img width="300" height="130" alt="example" :src="require('../../assets/images/model2.png')" />
              </template>
              <span style="letter-spacing: 0.3em;font-size: 20px;font-weight: bold">单木树种识别模型</span>
            </a-card>
          </a-space>
        </a-col>
  
        <a-col  style="overflow-x: scroll;overflow-y: hidden;" :span="18">
          <a-space>
            <a-card v-for="(item, index) in recoModel" :key="index" hoverable style="height: 200px;width:300px">
            <template #cover>
  
              <div style="padding: 20px">
                <router-link :to="{ path: 'model-desc', query: { modelName: item.modelName } }">
                  <div style="font-weight: bold;font-size:20px;margin-bottom:10px ">{{ item.modelName }}</div>
                </router-link>
                语言 · {{ item.frame }} <br>
                {{ JSON.parse(item.tags)[0] }} <br>
                {{ item.desc }}
              </div>
              <div style="border-top: 1px solid #dadce0;height: 50px;line-height: 50px;padding-left: 20px">
                <a-rate :value="3" />
              </div>
            </template>
          </a-card>
          </a-space>
        </a-col>
      </a-row>
  
    </div>
</template>
  
  <script>
  import axios from 'axios'
  export default {
    name:"model-pool",
    data(){
        return{
          selectedKeys : [1],
          visible : false,
          modalTitleRef : null,
          uploadString:false,
          uploadFile:false,
          uploadFromGitHub:false,
          value:"",
          recoModel: [],
       formState : {
        modelTitle: "",
        trainScript: "",
        predictScript: "",
        script: "",
      },
        }
    },
    mounted(){
        this.listRecognition()
        this.showUploadString()
    },
    methods:{
      handleOk () {
        console.log(e);
        this.visible = false;
      },      
       showModal  () {
        this.visible = true;
      },
       showUploadString  () {
        this.uploadString = true;
        this.uploadFile = false;
        this.uploadFromGitHub = false;
      },
       showUploadFile  ()  {
        this.uploadFile = true;
        this.uploadString = false;
        this.uploadFromGitHub = false;
      },
       showUploadFromGitHub  () {
        this.uploadFromGitHub = true;
        this.uploadString = false;
        this.uploadFile = false;
      },
       onFinishFailed (errorInfo)   {
        console.log('Failed:', errorInfo);
      },

       onSearch  (searchValue)  {
        console.log('use value', searchValue);
        console.log('or use this.value', value.value);
      },
      
       onFinish (values) {
        console.log('Success:', values);
        console.log(toRaw(values.file)[0].originFileObj);
        const formData = new FormData()
        formData.append("name", values.modelTitle)
        formData.append("trainScript", values.trainScript)
        formData.append("predictScript", values.predictScript)
        formData.append("file", toRaw(values.file)[0].originFileObj)
  
        axios.post("http://43.143.170.49:8466/api/aimodel/upload", formData, {
          headers: { Authorization: localStorage.getItem("token") }
        });
      },
       onFinishString(values) {
        console.log('Success:', values);
        const formData = new FormData()
        formData.append("name", values.modelTitle)
        formData.append("trainScript", values.trainScript)
        formData.append("predictScript", values.predictScript)
        formData.append("script", values.script)
        axios.post("http://43.143.170.49:8466/api/aimodel/uploadScript", formData, {
          headers: { Authorization: localStorage.getItem("token") }
        });
      },
       onFinishGithub (values) {
        console.log('Success:', values);
        const formData = new FormData()
        formData.append("name", values.modelTitle)
        formData.append("trainScript", values.trainScript)
        formData.append("predictScript", values.predictScript)
        formData.append("url", values.githubHref)
        axios.post("http://43.143.170.49:8466/api/aimodel/fromGithub", formData, {
          headers: { Authorization: localStorage.getItem("token") }
        });
      },
      
      listRecognition () {
        axios.get("http://43.143.170.49:8466/api/aimodel/listRecognition")
          .then(res => {
            this.recoModel = res.data.data
          })
          .catch(err => {
            console.log(err)
          })
      }
    }
  }
  </script>
  
  <style scoped>
  .uploadForm {
    padding: 0 10px;
    background-color: white;
  }
  </style>